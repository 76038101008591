/* ******* scroll  start*/
/* width */
/* 
************
:: custom color set ::
js: document.documentElement.style.setProperty(`--chat-box-bg`, "brown");
************
*/
/* - font size, height and margin top to send button for incress size - */
:root{
    --msg-l-bg: #f0f2f4;
    --msg-l-color: #5b5757;
    --msg-r-bg: #3e7bf4;
    --msg-r-color: white;
    --chat-box-bg: white;
    --chat-box-border-color: rgba(0, 0, 0, 0.125);

    --chat-popup-bg-color: white;
    --chat-popup-hov-bg-color: #f0f2f4;
    --chat-popup-text-color: black;

    --chat-notification-bg-color: white;
    --chat-notification-color: black;
}

::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px gainsboro; 
    border-radius: 5px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: lightgrey; 
    border-radius: 5px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--msg-r-bg); 
  }

/* ******* scroll  end*/

.b-0{
    border-width: 0px;
}

.flex-grow-0 {
    flex-grow: 0 !important;
}

.border-top {
    border-top: 1px solid var(--chat-box-border-color) !important;
}

/* background colors start */
.msg-bg-l{
    background-color: var(--msg-l-bg);
    color: var(--msg-l-color);
}
.msg-bg-r{
    background-color: var(--msg-r-bg);
    color: var(--msg-r-color);
}
.bg-input {
    background-color: var(--msg-l-bg);
    color: var(--msg-l-color);
}

/* background colors end */
body {
    background-color: transparent; /*lightsteelblue;*/
    font-size: 14px;
    line-height: 12pt;
    padding: 0px;
    /* box-shadow: rgba(241, 51, 51, 0.16) 0px 3px 6px, rgba(247, 226, 226, 0.23) 0px 3px 6px; */
    /* box-shadow: rgba(238, 9, 9, 0.589) 0px 0.0625em 0.0625em, rgba(231, 9, 9, 0.541) 0px 0.125em 0.5em, rgba(241, 19, 19, 0.397) 0px 0px 0px 1px inset; */
}

.chat-card {
    border-radius: 1rem;
    overflow: hidden;
    border-color: var(--chat-box-border-color);
    /* box-shadow: rgba(166, 205, 221, 0.658) 0px 2px 4px 0px, rgba(151, 203, 224, 0.603) 0px 2px 16px 0px; */
}

.chat-online {
    color: #34ce57;
}

.chat-offline {
    color: #e4606d;
}
.chat-box{
    background-color: var(--chat-box-bg);
}
.chat-messages {
    display: flex;
    flex-direction: column;
    /* max-height: 800px; */
    overflow-y: scroll;
    height: calc(100vh - 8.7rem);
}

.pin-message {
    position: absolute;
    width: 100%;
    background-color: var(--chat-box-bg);
    z-index: 1;

}

.chat-message-left,
.chat-message-right {
    display: flex;
    flex-shrink: 0;
    white-space: break-spaces;
    max-width: 85%;
    position: relative;
    /* transition: all 0.2s; */
}

.chat-message-left {
    margin-right: auto;
}

.chat-message-right {
    flex-direction: row-reverse;
    margin-left: auto;
}

.chat-ul-msg-options{
    list-style: none;
    padding: 5px;
    background-color: var(--chat-popup-bg-color);
    box-shadow: 0 0 3px rgb(86 96 117 / 70%);
    border-radius: 5px;
    z-index: 2;
}
.chat-btn-msg-option{
    background: none;
    /* color: var(--msg-r-color); */
}
.chat-btn-msg-option:focus{
    outline: none;
    /* color: var(--msg-r-color); */
}

.l-chat-btn-popup {
    color: var(--msg-l-color);
}

.r-chat-btn-popup {
    color: var(--msg-r-color);
}

.chat-li-msg-option{
    cursor: pointer;
    margin-bottom: 5px;
    padding: 5px;
    color: var(--chat-popup-text-color);
}
.chat-li-msg-option:hover{
    background-color: var(--chat-popup-hov-bg-color);
}

@media (max-height: 250px) {
    .chat-messages{
        height: 60vh;
    }
    .chat-message-left,
    .chat-message-right {
        max-width: 95%;    
    }    
}

.profile-img {
    width: 2.5rem;
    /* display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden; */
    text-align: center;
}
.profile-text {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 36px;
    font-size: 16px;
    font-weight: bold;
    background-color: rgb(240, 242, 244);
    padding-left: 4px;
    letter-spacing: 1px;
}
.msg-cont-marg-lft{
    margin-left: 3rem !important;
}

.msg-cont-marg-rght{
    margin-right: 3rem !important;
}

.chat-message-text{
    min-width: 100px;
}
.chat-message-text:after {
    content: '';
    position: absolute;
    top: 28px;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom: 0;
    margin-top: -28px;
}

.chat-message-text--left {
    border-top-right-radius: 0.7rem !important;
}
.chat-message-text--left:after
{
    left: 37px;
    border-top-color: var(--msg-l-bg);
    border-left-width: 15px;
}

.chat-message-text--right {
    border-top-left-radius: 0.7rem !important;
}
.chat-message-text--right:after {
    right: 37px;
    border-top-color: var(--msg-r-bg);
    border-right-width: 15px;
}

@media (max-width: 767px) {
    body{
        font-size: 12px;
        line-height: 10pt;
    }
    .chat-message-text--left{
        margin-left: 5px !important;
    }
    .chat-message-text--right{
        margin-right: 2px !important;
    }
    .chat-message-text--left:after{
        left: 34px;
    }
    .chat-message-text--right:after{
        right: 31px;
    }
    .msg-cont-marg-lft{
        margin-left: 44px !important;
    }
    .msg-cont-marg-rght{
        margin-right: 41px !important;
    }
}

.chat-input-parent{
    display: flex;
    align-items: center;
    border-radius: 50px;
    border: 1px solid #ced4da;
}

.chat-input{
    background-color: var(--msg-l-bg);
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    margin-left: 1rem;
    /* border-left: 0px; */
    /* border-right: 1px solid; */
    resize: none;
    height: 30px !important;
    color: var(--msg-l-color);
}

.chat-input::placeholder {
    color: var(--msg-l-color);
}

.chat-input:focus {
    background-color: var(--msg-l-bg);
    color: var(--msg-l-color);
    box-shadow: none;
}

@media (max-width: 767px) {
    .chat-input{
        height: 25px !important;
        font-size: 12px;
    }
}

.chat-input-emoji{
    padding: 2px;
    border: 0px;
}

.chat-input-send  {
    padding: 0px;
    font-size: 23px;
    color: var(--msg-r-bg);
    transition: transform 0.2s;
    border: 0px;
}

.chat-input-send:hover{
    color: var(--msg-r-bg);
}

.chat-input-send:focus{
    box-shadow: 0 0 0 0 rgb(0 123 255 / 25%);
    transform: scale(0.8);
}

.chat-notification-container{
    text-align: center;
    box-shadow: 0 0 3px rgb(86 96 117 / 70%);
    width: 50%;
    /* margin-left: auto; */
    /* margin-right: auto; */
    min-height: 35px;
    /* align-items: center; */
    /* justify-content: center; */
    padding-top: 10px;
    margin-bottom: 5px;
    position: absolute;
    bottom: 50px;
    right: 50%;
    transform: translateX(50%);
    background-color: var(--chat-notification-bg-color);
    border-radius: 5px;
    font-weight: bold;
    color: var(--chat-notification-color);
}

@media (max-width: 728px) {
    .chat-notification-container{
        width: 80%;
    }
}

.chat-notification-close {
    float: right;
    margin-right: 10px;
    margin-top: -0px;
    font-size: 15px;
    font-weight: bold;
}
.chat-btn-setting {
    position: absolute;
    z-index: 2;
    right: 25px;
    top: 10px;
    width: 25px;
    height: 25px;
    background-color: var(--msg-l-bg);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    padding-bottom: 0px;
    font-size: 15px;
}

.chat-btn-setting-text {
    color: var(--msg-l-color);
}

.btn-custom-pop-out {
    position: absolute;
    top: 0;
    color: #a3a3a3;
    z-index: 1;
}